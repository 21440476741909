import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"; 


Vue.use(Toast, {  
  timeout: 3000,
  position: "top-center"
});
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
