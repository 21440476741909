import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'deliverable',
    component: () => import(/* webpackChunkName: "doc" */ '../views/Deliverable.vue'),
    meta: {
      title: 'VDOT Deliverable App'
    }
    // name: 'Home',
    // component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  }
  // ,
  // {
  //   path: '/vdotdeliverable/:id?',
  //   name: 'deliverable',
  //   component: () => import(/* webpackChunkName: "doc" */ '../views/Deliverable.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
