import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme:{
        themes: {
            light: {
                primary: '#EE2E24',
                secondary: '#484A47',
                accent: '#1B2A39',
                error: '#CC0000',
                info: '#DBDBDB',
                success: '#4CAF50',
                warning: '#FFC107'
            }
        }
      }
});
